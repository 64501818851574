import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import * as S from '../styles/pages/priceListStyle'
import * as G from '../styles/globalStyle'

export default function PriceList({ data }) {
  const priceList = data.prices.edges.sort((a, b) => {
    return a.node.frontmatter.order - b.node.frontmatter.order
  })

  return (
    <Layout>
      <G.Container>
        <S.MainHeader>Cennik</S.MainHeader>
        {/* <S.PriceListContainer>
          {priceList.map(element => {
            return (
              <div key={element.node.frontmatter.name}>
                <S.TableTitle>{element.node.frontmatter.name}</S.TableTitle>
                <S.TitleDevider />
                <S.TableWraper
                  dangerouslySetInnerHTML={{ __html: element.node.html }}
                ></S.TableWraper>
              </div>
            )
          })}
        </S.PriceListContainer> */}
        <S.PlaceholderContainer>
          <S.Placeholder>
            Aktualne ceny znajdą Państwo na platformie{' '}
            <S.Link
              href="https://booksy.com/pl-pl/133824_salon-evita-kosmetyka-profesjonalna-ewa-plekaniec_salon-kosmetyczny_10534_zywiec#ba_s=sr_1"
              target="__blank"
            >
              Booksy
            </S.Link>
          </S.Placeholder>
        </S.PlaceholderContainer>
      </G.Container>
    </Layout>
  )
}

export const query = graphql`
  query PriceListQuery {
    prices: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "markdowns/priceList/.*.md/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            order
          }
        }
      }
    }
  }
`
