import styled from 'styled-components'
import * as G from '../globalStyle'
import Img from 'gatsby-image'
//Hero Section

export const HeroSection = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.size.large}) {
    padding-bottom: 150px;
    padding-top: 115px;
  }
`

export const PriceListContainer = styled.div`
  max-width: 650px;
  width: 100%;
  margin: auto;
  margin-top: 70px;

  table {
    width: 100%;
    color: ${({ theme }) => theme.color.secondary};
    text-align: left;
    margin-bottom: 40px;
    padding: 0 15px;
    border-collapse: collapse;
    font-family: 'Book Antiqua';
  }

  td {
    padding: 8px 0;

    &:first-child {
      padding-right: 15px;
    }

    &:nth-child(2) {
      white-space: nowrap;
      text-align: right;
    }
  }

  th {
    display: none;
  }
`

export const MainHeader = styled(G.MainHeader)`
  width: 100%;
  text-align: center;
`

export const TableTitle = styled.h2`
  text-align: left;
  font-size: 28px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondary};
`

export const TableWraper = styled.div`
  padding: 0 10px;
`

export const TitleDevider = styled.span`
  height: 1px;
  display: block;
  background: ${({ theme }) => theme.color.gold};
  width: 300px;
  margin-right: auto;
  margin-left: 0;
  margin-top: 9px;
  margin-bottom: 1px;
`

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const Placeholder = styled.h3`
  font-family: 'Book Antiqua';
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  margin: auto;
  margin-top: 60px;
  line-height: 30px;
  padding: 0 20px;

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 24px;
    line-height: 34px;
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme.color.gold};
  text-decoration: none;
`
